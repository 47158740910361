<!--
 * @Author: xingwanjiang
 * @Date: 2022-03-08 16:57:31
 * @LastEditors: xingwanjiang
 * @LastEditTime: 2022-05-20 11:05:29
 * @Description: 登陆页面
-->
<template>
  <div class="login">
    <div class="login-content">
      <el-card>
        <div class="login-content-title">设备健康牌</div>
        <el-form :model="ruleForm" size="small" ref="ruleForm" class="login-content-form" v-loading="loading">
          <el-form-item prop="region">
            <el-cascader v-model="value" :options="options" :show-all-levels="false" />
          </el-form-item>
          <!-- <el-form-item prop="name">
            <el-input v-model="ruleForm.name" placeholder="请输入用户名">
              <i slot="prefix" class="el-input__icon el-icon-s-custom"></i>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input v-model="ruleForm.password" placeholder="请输入密码">
              <i slot="prefix" class="el-input__icon el-icon-lock"></i>
            </el-input>
          </el-form-item> -->
          <el-button class="login-content-button" type="success" size="mini" @click="handleLogin">查看设备详情</el-button>
        </el-form>
      </el-card>
    </div>
  </div>
</template>
<script>
import { fetchHospInfo } from '../api/index.js'
export default {
  data() {
    return {
      loading: false,
      ruleForm: {},
      options: [],
      value: ''
    }
  },
  created() {
    this.init()
  },
  methods: {
    // getQueryString(name) {
    //   var reg = new RegExp('[?&]' + name + '=([^&#]*)', 'i')
    //   var res = window.location.href.match(reg)

    //   if (res && res.length > 1) {
    //     return decodeURIComponent(res[1])
    //   }
    //   return ''
    // },
    /**
     * @description: 初始化
     * @param {*}
     * @return {*}
     */

    init() {
      this.loading = true
      fetchHospInfo({}).then(res => {
        if (res.code === 200) {
          const data = res.data.biAreaInfoExtendDTOList
          this.handleDealData(data)
        }
      })
    },
    /**
     * @description: 数据处理
     * @param {*}
     * @return {*}
     */

    handleDealData(data) {
      const hospData = []
      const result = data
      result.map(item => {
        const hosItem = {
          label: item.provinceName,
          value: item.provinceCode,
          children: []
        }
        if (item.biAreaInfoDTOList && item.biAreaInfoDTOList) {
          const areaData = item.biAreaInfoDTOList
          areaData.map(v => {
            hosItem.children.push({
              label: v.cityName,
              value: v.cityCode,
              children: v.biHospitalInfoDTOList
            })
            const equip = v.biHospitalInfoDTOList
            if (equip && equip.length > 0) {
              equip.map(n => {
                n.value = n.hospitalName
                n.label = n.hospitalName
                n.children = n.biEquipInfoDTOList
                const requip = n.biEquipInfoDTOList
                if (requip && requip.length > 0) {
                  requip.map(m => {
                    m.label = m.hospitalEquipName || m.equipName
                    m.value = m.bid
                  })
                }
              })
            }
          })
        } else {
          hosItem.chilren = null
        }
        hospData.push(hosItem)
      })
      this.loading = false
      this.options = hospData
    },
    /**
     * @description: 登录
     * @param {*}
     * @return {*}
     */

    handleLogin() {
      const result = this.value
      if (result && result.length === 4 && result[3]) {
        const bid = result[3]
        this.$router.push({
          path: '/detail',
          query: { bid: bid }
        })
      } else {
        this.$message.error('无效选择!')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url('../assets/login_bg.jpg');
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  &-content {
    /* width: 25%; */
    &-title {
      width: 100%;
      padding: 0.3rem 0;
      text-align: center;
      color: #0199e1;
      font-size: 0.24rem;
    }
    &-form {
      padding: 0.2rem;
    }
    &-button {
      width: 100%;
    }
  }
}
</style>

